/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import FormCheckboxList from '../../../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDateSelector from '../../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../../components/FormInputs/FormRadioList/FormRadioList';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import Panel from '../../../../components/Panel/Panel';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearLEAManagement,
  saveLEAManagement,
  selectLEAManagementDataEntryMode,
  selectLEAManagementFormViewModel,
  selectLEAManagementLEAManagement,
  selectLEAManagementLoadingFormViewModel,
  selectLEAManagementLoadingLEAManagement,
  setLEAManagementDataEntryMode,
} from '../../../../redux/slices/leaManagementSlice';
import {
  fetchLEAManagementById,
  fetchLEAManagementFormViewModel,
} from '../../../../redux/thunks/leaManagementThunks';
import {
  createLEAManagement,
  updateLEAManagement,
} from '../../../../services/leaManagement.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { LEAManagementFormViewModel } from '../../../../types/LEAManagementFormViewModel';
import { LEAManagementModel } from '../../../../types/LEAManagementModel';
import { Tabs_DISTRICT_SCHOOL } from '../../../../types/NavigationTabs';
import { PanelType } from '../../../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../../../types/propTypes/FormDropdownPropTypes';
import { FormRadioListOrientation } from '../../../../types/propTypes/FormRadioListPropTypes';
import { FormTextboxChangeEventConfig } from '../../../../types/propTypes/FormTextboxPropTypes';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { LEAManagementValidationEditSchema } from '../../../../types/Validation/LEAManagementValidationEditSchema';
import { LEAManagementValidationSchema } from '../../../../types/Validation/LEAManagementValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import {
  getCountyAnsiCodeFromOptions,
  isDistrictAdmin,
  isStateAdmin,
} from '../../../../utilities/userUtilities';
import IsRequiredCalculator from './IsRequiredCalculator';
import './LEAManagementInput.css';

const LEAManagementInput = (): ReactElement => {
  const DEFAULT_STATE = 'Mississippi';
  const leaManagementModel: LEAManagementModel = useAppSelector(
    selectLEAManagementLEAManagement
  );
  const formViewModel: LEAManagementFormViewModel = useAppSelector(
    selectLEAManagementFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectLEAManagementLoadingFormViewModel
  );
  const isLoadingLEAManagementModel = useAppSelector(
    selectLEAManagementLoadingLEAManagement
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectLEAManagementDataEntryMode
  );
  const isLoading = isLoadingLEAManagementModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
    {
      statusCode: 409,
      howToFix:
        'The provided LEA Management ID is already in the system. Use another value, or return to the list and edit the existing LEA Management.',
    },
  ];
  const formValues: LEAManagementModel = leaManagementModel;
  const [areMailingAddressFieldsRequired, setAreMailingAddressFieldsRequired] =
    useState(false);

  const getRequiredMailingAddressLabel = (): string => {
    return areMailingAddressFieldsRequired ? 'forced-required-label' : '';
  };

  const updateMailingAddressRequiredFlag = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    const isRequired =
      config.value === '' &&
      formValues.mailingAddressStreetNumberAndName === '' &&
      formValues.mailingCity === '' &&
      formValues.mailingState === '' &&
      formValues.mailingZipCode === ''
        ? false
        : true;
    setAreMailingAddressFieldsRequired(isRequired);
  };

  const [
    arePhysicalAddressFieldsRequired,
    setArePhysicalAddressFieldsRequired,
  ] = useState(false);

  const getRequiredPhysicalAddressLabel = (): string => {
    return arePhysicalAddressFieldsRequired ? 'forced-required-label' : '';
  };

  const updatePhysicalAddressRequiredFlag = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    const isRequired =
      config.value === '' &&
      formValues.physicalAddressStreetNumberAndName === '' &&
      formValues.physicalCity === '' &&
      formValues.physicalState === '' &&
      formValues.physicalZipCode === ''
        ? false
        : true;
    setArePhysicalAddressFieldsRequired(isRequired);
  };

  useEffect(() => {
    const isPhysicalAddressRequired =
      formValues.physicalAddressStreetNumberAndName === '' &&
      formValues.physicalCity === '' &&
      formValues.physicalState === '' &&
      formValues.physicalZipCode === ''
        ? false
        : true;
    setArePhysicalAddressFieldsRequired(isPhysicalAddressRequired);

    const isMailingAddressRequired =
      formValues.mailingAddressStreetNumberAndName === '' &&
      formValues.mailingCity === '' &&
      formValues.mailingState === '' &&
      formValues.mailingZipCode === ''
        ? false
        : true;
    setAreMailingAddressFieldsRequired(isMailingAddressRequired);
  }, [formValues, leaManagementModel]);

  const [isShowMailingCounty, setIsShowMailingCounty] = useState(false);

  const updateShowMailingCounty = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const showCounty = config.value === DEFAULT_STATE ? true : false;
    setIsShowMailingCounty(showCounty);
    setAreMailingAddressFieldsRequired(true);
  };

  const updateMailingCountyAnsiCode = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const mailingCountyAnsiCode = getCountyAnsiCodeFromOptions(
      formViewModel.countyAnsiCodeOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && mailingCountyAnsiCode) {
      config.setValueHook('mailingCountyAnsiCode', mailingCountyAnsiCode);
    }
  };

  const [isShowPhysicalCounty, setIsShowPhysicalCounty] = useState(false);

  const updateShowPhysicalCounty = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const showCounty = config.value === DEFAULT_STATE ? true : false;
    setIsShowPhysicalCounty(showCounty);
    setArePhysicalAddressFieldsRequired(true);
  };

  const updatePhysicalCountyAnsiCode = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const physicalCountyAnsiCode = getCountyAnsiCodeFromOptions(
      formViewModel.countyAnsiCodeOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && physicalCountyAnsiCode) {
      config.setValueHook('physicalCountyAnsiCode', physicalCountyAnsiCode);
    }
  };

  useEffect(() => {
    if (leaManagementModel.physicalState === DEFAULT_STATE) {
      setIsShowPhysicalCounty(true);
    }
    if (leaManagementModel.mailingState === DEFAULT_STATE) {
      setIsShowMailingCounty(true);
    }
  }, [leaManagementModel.physicalState, leaManagementModel.mailingState]);

  return (
    <ManualInputPage<LEAManagementModel, LEAManagementFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'lea-management-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_DISTRICT_SCHOOL)}
      classes={{ pageClass: 'data-input--lea-management--input' }}
      parameterOptions={{
        routeParameterName: 'leaId',
        identityName: leaManagementModel.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.LEAManagementList,
        cancelRoute: appRoutePaths.LEAManagementList,
        createSuccessRoute: appRoutePaths.LEAManagementInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchLEAManagementById,
        loadFormDetail: fetchLEAManagementFormViewModel,
        setEntryMode: setLEAManagementDataEntryMode,
        setData: saveLEAManagement,
        clearData: clearLEAManagement,
        onCreate: createLEAManagement,
        onUpdate: updateLEAManagement,
      }}
      formDetails={{
        validationSchema:
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema,
        initialFormValues: leaManagementModel,
        isFormModelEmpty: !formViewModel.leaTypeOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'LEA Management',
        pageHeader: 'LEA & School Info',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="lea-management-input-child">
        <Panel panelType={PanelType.INFO} heading="LEA Information">
          <div className="field-row">
            <FormTextbox
              displayName="LEA Name"
              field="leaName"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Organization Type"
              field="organizationType"
              disabled={true}
            />
            <FormDropdown
              displayName="LEA Type"
              field="leaType"
              options={formViewModel.leaTypeOptions}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="LEA Identifier"
              field="leaIdentifier"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="NCES Identifier"
              field="ncesIdentifier"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormDropdown
              displayName="LEA Operational Status"
              field="leaOperationalStatus"
              options={formViewModel.leaOperationalStatusOptions}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormDateSelector
              displayName="Operational Status Effective Date"
              field="operationalStatusEffectiveDate"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row">
            <FormRadioList
              displayName="Charter School"
              field="charterSchoolIndicator"
              options={formViewModel.charterSchoolIndicatorOptions}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormRadioList
              displayName="Reported Federally"
              field="reportedFederallyIndicator"
              options={formViewModel.reportedFederallyIndicatorOptions}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row">
            <FormDropdown
              displayName="Pre-K Eligibility"
              field="prekEligibility"
              options={formViewModel.prekEligibilityOptions}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormDropdown
              displayName="Pre-K Eligibility Ages for Non-IDEA Students"
              field="prekEligibilityAgesForNonIdeaStudents"
              options={
                formViewModel.prekEligibilityAgesForNonIdeaStudentsOptions
              }
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row field-row-grid-1-1-2">
            <FormTextbox
              displayName="Website"
              field="website"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Telephone Number"
              field="telephoneNumber"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
            <FormCheckboxList
              displayName="Grades"
              field="grades"
              options={formViewModel.gradesOptions}
              addAllCheckbox={true}
              classConfig={{ containerClasses: 'grades-container' }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
          </div>
        </Panel>
        <Panel panelType={PanelType.INFO} heading="MSIS Primary Information">
          <div className="field-row">
            <FormTextbox
              displayName="First Name"
              field="primaryFirstName"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
            <FormTextbox
              displayName="Last Name"
              field="primaryLastName"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="Email Address"
              field="primaryEmailAddress"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
            <FormTextbox
              displayName="Telephone Number"
              field="primaryTelephoneNumber"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
          </div>
        </Panel>
        <Panel panelType={PanelType.INFO} heading="MSIS Secondary Information">
          <div className="field-row">
            <FormTextbox
              displayName="First Name"
              field="secondaryFirstName"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
            <FormTextbox
              displayName="Last Name"
              field="secondaryLastName"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="Email Address"
              field="secondaryEmailAddress"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
            <FormTextbox
              displayName="Telephone Number"
              field="secondaryTelephoneNumber"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin() && !isDistrictAdmin()}
            />
          </div>
        </Panel>
        <Panel panelType={PanelType.INFO} heading="Physical Address">
          <div className="field-row">
            <FormTextbox
              displayName="Address Street Number and Name"
              field="physicalAddressStreetNumberAndName"
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updatePhysicalAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Apt., Suite, Unit, Building, Floor, Etc."
              field="physicalAptSuiteBuildingFloorNumber"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row">
            <FormTextbox
              displayName="Physical City"
              field="physicalCity"
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updatePhysicalAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormDropdown
              displayName="State"
              field="physicalState"
              options={formViewModel.stateOptions}
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updateShowPhysicalCounty}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Zip Code"
              field="physicalZipCode"
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updatePhysicalAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
          </div>
          {isShowPhysicalCounty && (
            <div className="field-row">
              <FormDropdown
                displayName="County"
                field="physicalCounty"
                options={formViewModel.countyOptions}
                classConfig={{
                  labelClasses: getRequiredPhysicalAddressLabel(),
                }}
                validationSchema={
                  dataEntryMode === 'new'
                    ? LEAManagementValidationSchema
                    : LEAManagementValidationEditSchema
                }
                onChangeEvent={updatePhysicalCountyAnsiCode}
                disabled={!isStateAdmin()}
              />
              <FormTextbox
                displayName="County ANSI Code"
                field="physicalCountyAnsiCode"
                disabled={true}
              />
            </div>
          )}
        </Panel>
        <Panel panelType={PanelType.INFO} heading="Mailing Address">
          <div className="field-row">
            <FormTextbox
              displayName="Address Street Number and Name"
              field="mailingAddressStreetNumberAndName"
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updateMailingAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Apt., Suite, Unit, Building, Floor, Etc."
              field="mailingAptSuiteBuildingFloorNumber"
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row">
            <FormTextbox
              displayName="City"
              field="mailingCity"
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updateMailingAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormDropdown
              displayName="State"
              field="mailingState"
              options={formViewModel.stateOptions}
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updateShowMailingCounty}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Zip Code"
              field="mailingZipCode"
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updateMailingAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
          </div>
          {isShowMailingCounty && (
            <div className="field-row">
              <FormDropdown
                displayName="County"
                field="mailingCounty"
                options={formViewModel.countyOptions}
                classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
                validationSchema={
                  dataEntryMode === 'new'
                    ? LEAManagementValidationSchema
                    : LEAManagementValidationEditSchema
                }
                onChangeEvent={updateMailingCountyAnsiCode}
                disabled={!isStateAdmin()}
              />
              <FormTextbox
                displayName="County ANSI Code"
                field="mailingCountyAnsiCode"
                disabled={true}
              />
            </div>
          )}
        </Panel>
      </div>
      <IsRequiredCalculator
        updateMailingAddressRequiredFlag={setAreMailingAddressFieldsRequired}
      />
    </ManualInputPage>
  );
};

export default LEAManagementInput;
