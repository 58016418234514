/* eslint-disable max-len */
import { useField, useFormikContext } from 'formik';
import { ReactElement, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectNonPublicStudentDataEntryMode } from '../../../redux/slices/nonPublicStudentSlice';
import { isValidCode } from '../../../services/base.service';
import { getStudentByMSISId } from '../../../services/nonPublicStudent.service';
import { ButtonSize } from '../../../types/ButtonSize';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { NonPublicStudentEntryModel } from '../../../types/NonPublicStudentEntryModel';
import { NonPublicStudentSearchResultModel } from '../../../types/NonPublicStudentSearchResultModel';
import { PanelType } from '../../../types/PanelType';
import { NonPublicStudentEntryValidationSchema } from '../../../types/Validation/NonPublicStudentEntryValidationSchema';
import ActionButton from '../../ActionButton/ActionButton';
import FormDateSelector from '../../FormInputs/FormDateSelector/FormDateSelector';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';
import './NPSE_StudentPanel.css';

const NPSE_StudentPanel = (): ReactElement => {
  const { values, setFieldValue } =
    useFormikContext<NonPublicStudentEntryModel>();

  const [, msisIdMetaProps] = useField('studentIdentifier');
  const getIsMsisIDValid = (): boolean => !msisIdMetaProps?.error;

  const dataEntryMode: DataEntryMode = useAppSelector(
    selectNonPublicStudentDataEntryMode
  );

  const hasMatch: boolean =
    dataEntryMode === DataEntryMode.EDIT || values.firstName.length > 0;

  const [searching, setSearching] = useState(false);
  const [hasSearchMatch, setHasSearchMatch] = useState(hasMatch);
  const [searchMessage, setSearchMessage] = useState('');

  const searchDisabled =
    dataEntryMode === DataEntryMode.EDIT ||
    !getIsMsisIDValid() ||
    searching ||
    hasSearchMatch;

  const handleMSISSearch = async (): Promise<void> => {
    const setUserFields = (data: NonPublicStudentSearchResultModel): void => {
      setFieldValue('firstName', data.firstName);
      setFieldValue('lastOrSurname', data.lastOrSurname);
      /* istanbul ignore next */
      setFieldValue('middleName', data.middleName || '');
      setFieldValue('birthDate', data.birthDate);
      /* istanbul ignore next */
      setFieldValue('personIdentifierSsn', data.personIdentifierSsn || '');

      setFieldValue('hasMatch', true);
    };

    setSearching(true);
    setHasSearchMatch(false);
    setSearchMessage('');

    const response = await getStudentByMSISId(values.studentIdentifier);
    if (isValidCode(response.status)) {
      setUserFields(response.data);
      setHasSearchMatch(true);
      setFieldValue('ideaEducationalEnvironment', '');
    } else {
      // ANY Error Code
      setSearchMessage(
        'MSIS ID Not Found. Please ensure the MSIS ID has been created for the student.'
      );
    }

    setSearching(false);
  };

  const handleMsisValueChange = (): Promise<void> => {
    const resetMatchedFields = (): void => {
      setFieldValue('firstName', '');
      setFieldValue('lastOrSurname', '');
      setFieldValue('middleName', '');
      setFieldValue('birthDate', '');
      setFieldValue('personIdentifierSsn', '');

      setFieldValue('hasMatch', false);
    };

    setSearchMessage('');

    // Change after match
    if (hasSearchMatch) {
      resetMatchedFields();
      setHasSearchMatch(false);
    }

    return Promise.resolve();
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Student Information">
      <div className="field-row field-row-msis-id">
        <div>
          <FormTextbox
            displayName="Enter MSIS ID"
            field="studentIdentifier"
            validationSchema={NonPublicStudentEntryValidationSchema}
            disabled={dataEntryMode === DataEntryMode.EDIT}
            onChangeEvent={handleMsisValueChange}
          />
        </div>
        <div className="search-button">
          <ActionButton
            classes="button--secondary"
            size={ButtonSize.SMALL}
            onClick={handleMSISSearch}
            disabled={searchDisabled}
            dataTestId="student-search-button"
            tooltipText="Click to search for student"
            loading={searching}
          >
            Search
          </ActionButton>
        </div>
        <div className="search-message" data-testid="search-error-message">
          {searchMessage}
        </div>
      </div>

      <div className="field-row field-row-msis-search-results">
        <FormTextbox
          displayName="Last Name"
          field="lastOrSurname"
          disabled={true}
          classConfig={{ labelClasses: 'forced-required-label' }}
        />
        <FormTextbox
          displayName="First Name"
          field="firstName"
          disabled={true}
          classConfig={{ labelClasses: 'forced-required-label' }}
        />
        <FormTextbox
          displayName="Middle Name"
          field="middleName"
          disabled={true}
        />
        <FormDateSelector
          displayName="Date of Birth"
          field="birthDate"
          placeholderText=""
          disabled={true}
          classConfig={{ labelClasses: 'forced-required-label' }}
        />
        <FormTextbox
          displayName="SSN"
          field="personIdentifierSsn"
          disabled={true}
        />
      </div>
    </Panel>
  );
};

export default NPSE_StudentPanel;
